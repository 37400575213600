<template>
  <v-container>
    <alert-snack-bar
      v-model="state.alertSnackBar.isShow"
      :type="state.alertSnackBar.type"
      :title="state.alertSnackBar.title"
      :message="state.alertSnackBar.message"
    />
    <v-row>
      <v-col xl="12" cols="12" class="d-inline-flex">
        <h3 class="my-3">イベントの編集</h3>
        <v-spacer />
        <v-btn
          variant="outlined"
          class="my-3"
          :loading="state.registerLoading"
          :disabled="state.disabled"
          @click="funcs.delete_event"
        >
          <v-icon size="x-large">mdi-delete</v-icon>
          イベントを削除
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet color="white" elevation="1" class="pa-2">
          <v-form ref="form" v-model="state.formState">
            <v-row>
              <v-col xl="12" cols="12">
                <v-text-field
                  v-model="state.event.event_name"
                  color="primary"
                  counter="22"
                  density="compact"
                  persistent-counter
                  label="イベント名"
                  variant="outlined"
                  class="input-field-22c"
                  :rules="funcs.rules.letters1_22"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-inline-flex justify-start">
                <v-combobox
                  v-model="state.event.set"
                  color="primary"
                  counter="3"
                  density="compact"
                  persistent-counter
                  label="セット"
                  variant="outlined"
                  class="mr-5 input-field-3c"
                  :items="state.maintenanceClassification.set"
                  :rules="funcs.rules.letters1_3"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
                <v-combobox
                  v-model="state.event.subject1"
                  color="primary"
                  counter="3"
                  density="compact"
                  persistent-counter
                  label="対象1"
                  variant="outlined"
                  class="mr-5 input-field-3c"
                  :items="state.maintenanceClassification.subject1"
                  :rules="funcs.rules.letters1_3"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
                <v-combobox
                  v-model="state.event.subject2"
                  color="primary"
                  counter="3"
                  density="compact"
                  persistent-counter
                  label="対象2"
                  variant="outlined"
                  class="mr-5 input-field-3c"
                  :items="state.maintenanceClassification.subject2"
                  :rules="funcs.rules.letters1_3"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
                <v-combobox
                  v-model="state.event.subject3"
                  color="primary"
                  counter="3"
                  density="compact"
                  persistent-counter
                  label="対象3"
                  variant="outlined"
                  class="mr-5 input-field-3c"
                  :items="state.maintenanceClassification.subject3"
                  :rules="funcs.rules.letters1_3"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2" />
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">計画日</h4>
                <v-menu
                  v-model="state.datepicker.isShow"
                  location="bottom"
                  min-width="auto"
                  offset="40"
                  :close-on-content-click="false"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      v-bind="props"
                      v-model="state.datepicker.displayDate"
                      readonly
                      append-inner-icon="mdi-calendar"
                      color="primary"
                      density="compact"
                      variant="outlined"
                      class="datepicker-textfield input-shift"
                      :rules="funcs.rules.scheduleDate"
                      :loading="state.loading"
                      :disabled="state.disabled"
                    />
                  </template>
                  <v-locale-provider locale="ja">
                    <v-date-picker
                      v-model="state.datepicker.inputDate"
                      color="primary"
                      header=""
                      :title="
                        $dayjs(state.datepicker.inputDate).format('YYYY年')
                      "
                      class="elevation-1"
                      @update:model-value="state.datepicker.isShow = false"
                    />
                  </v-locale-provider>
                </v-menu>
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2" />
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">表示色</h4>
                <v-select
                  v-model="state.event.display_colorcode"
                  chips
                  color="primary"
                  density="compact"
                  variant="outlined"
                  class="input-field-color"
                  :items="colorItems"
                  :loading="state.loading"
                  :disabled="state.disabled"
                >
                  <template #chip="{ item, props }">
                    <v-avatar
                      v-bind="props"
                      size="x-small"
                      :color="item.value"
                    />
                  </template>
                  <template #item="{ item, props }">
                    <v-list-item
                      v-bind="props"
                      color="primary"
                      density="compact"
                    >
                      <template #title>
                        <v-avatar size="x-small" :color="item.value" />
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-7" />
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">補足説明</h4>
                <h5 class="mb-2 complement_font">
                  イベントの説明 (200文字まで)
                </h5>
                <v-textarea
                  v-model="state.event.event_description"
                  color="primary"
                  label="テキストの入力"
                  counter="200"
                  persistent-counter
                  :rules="funcs.rules.letters0_200"
                  :loading="state.loading"
                  :disabled="state.disabled"
                  variant="outlined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h4 class="mb-5">写真の添付 (最大3つ)</h4>
                <v-btn
                  block
                  variant="outlined"
                  class="img_area"
                  :loading="state.loading"
                  :disabled="state.disabled"
                  @click="funcs.openInput"
                >
                  <v-icon class="my-2" alt="search icon" size="x-large"
                    >mdi-plus</v-icon
                  >
                </v-btn>
                <input
                  id="img"
                  type="file"
                  accept="image/jpeg, image/png"
                  style="display: none"
                  multiple
                  @change="funcs.loadImages"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="image in calculated.displayImages.value"
                :key="image.id"
              >
                <img
                  :src="image.url"
                  alt="attachments"
                  style="max-width: 300px; border: solid 1px black"
                />
                <v-btn
                  class="mx-2"
                  icon
                  size="x-small"
                  variant="tonal"
                  :loading="state.loading"
                  :disabled="state.disabled"
                  @click="funcs.delete_preview(image)"
                  ><v-icon>mdi-minus</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="12" cols="12">
                <h5 class="mb-2 complement_font">申送事項 (300文字まで)</h5>
                <v-textarea
                  v-model="state.event.message"
                  color="primary"
                  label="テキストの入力"
                  counter="300"
                  persistent-counter
                  :rules="funcs.rules.letters0_300"
                  :loading="state.loading"
                  :disabled="state.disabled"
                  variant="outlined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="12" cols="12" class="d-flex justify-end">
                <v-btn
                  variant="outlined"
                  class="mr-4 cancel-btn"
                  :to="funcs.create_redirect_location()"
                  :loading="state.loading"
                  :disabled="state.disabled"
                  >キャンセル</v-btn
                >
                <v-btn
                  variant="flat"
                  :color="
                    !calculated.disableUpdateButton.value
                      ? 'primary'
                      : undefined
                  "
                  class="mr-4"
                  :loading="state.registerLoading"
                  :disabled="calculated.disableUpdateButton.value"
                  @click="funcs.update_event"
                  >更新</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import AlertSnackBar from "@/components/AlertSnackBar.vue";
import { computed, reactive, watch } from "vue";
import { RouteLocationNamedRaw, useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { get_base64_data_url } from "@/helpers/get_base64_data_url_from_file";
import { ulid } from "@/helpers/ulid";
import { AlertSnackBarModel } from "@/models/snackbar";
import { colorItems, acceptImageMimeTypes } from "@/models/MaintEvent";
import { MaintEventData, maxImageSize } from "@/models/MaintEvent";
import { connectToApi } from "@/helpers/connectToApi";
import { basename } from "@/helpers/basename";
import { sleep } from "@/helpers/sleep";
import { requestGetMaintenanceClassification } from "@/helpers/api/getMaintenanceClassification";
import { useAuthoritiesStore } from "@/stores/authorities";

dayjs.extend(isSameOrAfter);

/**
 * maint_event用のState
 */
type EventState = {
  maint_event_id: number;
  event_name: string;
  set: string | undefined;
  subject1: string | undefined;
  subject2: string | undefined;
  subject3: string | undefined;
  scheduled_date: string;
  display_colorcode: string;
  event_description: string;
  message: string;
};

/**
 * v-combobox用のitemsを入れておくための箱
 */
type MaintenanceClassification = {
  set: string[];
  subject1: string[];
  subject2: string[];
  subject3: string[];
};

/**
 * DatePicker用のState
 */
type DatePickerState = {
  isShow: boolean;
  inputDate: Date | undefined;
  displayDate: string;
};

/**
 * 画像を扱うためのType
 */
type Image = {
  readonly id: string;
  // input[type="file"]が渡してくれるFileインスタンス
  // undefinedならAPIから取得した画像の場合
  readonly file?: File;
  readonly url: string;
  readonly type: string;
  // 削除対象かを示すフラグ。APIから取得した画像の場合のみ使う
  isDelete: boolean;
};

/**
 * reactive()に渡すためのState。基本的にtemplateなどで使用する変数はここに置く。
 */
type State = {
  alertSnackBar: AlertSnackBarModel;
  disabled: boolean;
  loading: boolean;
  registerLoading: boolean;
  formState: boolean;
  event: EventState;
  maintenanceClassification: MaintenanceClassification;
  datepicker: DatePickerState;
  images: Image[];
};

/**
 * Apiから画面に必要な値を取得する際に使用するType
 */
type ApiResponse = {
  maint_event: MaintEventData;
};

/**
 * このViewコンポーネントえ使用するProps
 */
type Props = {
  maintEventId: string;
};

/**
 * API updateMaintEventを叩いた際にエラーが起きたことを記録するためのクラス
 */
class UpdateEventError {
  message: string;
  isCompletedOrDeleted: boolean;
  constructor(message: string, isCompletedOrDeleted: boolean) {
    this.message = message;
    this.isCompletedOrDeleted = isCompletedOrDeleted;
  }
}

/**
 * API uploadEventImageを叩いた際にエラーが起きたことを記録するためのクラス
 */
class UploadImageError {
  message: string;
  isCompletedOrDeleted: boolean;
  constructor(message: string, isCompletedOrDeleted: boolean) {
    this.message = message;
    this.isCompletedOrDeleted = isCompletedOrDeleted;
  }
}

/**
 * API deleteEventImageを叩いた際にエラーが起きたことを記録するためのクラス
 */
class DeleteImageError {
  message: string;
  isCompletedOrDeleted: boolean;
  constructor(message: string, isCompletedOrDeleted: boolean) {
    this.message = message;
    this.isCompletedOrDeleted = isCompletedOrDeleted;
  }
}

/**
 * API deleteMaintEventを叩いた際にエラーが起きたことを記録するためのクラス
 */
class DeleteEventError {
  message: string;
  isCompletedOrDeleted: boolean;
  constructor(message: string, isCompletedOrDeleted: boolean) {
    this.message = message;
    this.isCompletedOrDeleted = isCompletedOrDeleted;
  }
}

/**
 * API uploadEventImageを叩いた際に正常に完了したことを記録するためのクラス
 * 同時に正常にアップロードできた画像のIDも記録する
 */
class SuccessUploadImage {
  image_id: string;

  constructor(image_id: string) {
    this.image_id = image_id;
  }
}

/**
 * API deleteEventImageを叩いた際に正常に完了したことを記録するクラス
 * 同時に正常に削除できた画像のIDも記録する
 */
class SuccessDeleteImage {
  image_id: string;

  constructor(image_id: string) {
    this.image_id = image_id;
  }
}

/**
 * PromiseSettledResult<T>オブジェクトがPromiseRejectedResultであることを識別するユーザ定義型ガード
 */
const isPromiseRejectedResult = (
  v: PromiseSettledResult<any>,
): v is PromiseRejectedResult => {
  return v.status === "rejected";
};

const prop = defineProps<Props>();

const storeAuthorities = useAuthoritiesStore();
const route = useRoute();
const router = useRouter();

const state = reactive<State>({
  alertSnackBar: {
    isShow: false,
    type: "error",
    title: "データの更新に失敗しました",
    message: "データの更新に関する不具合が発生しています。",
  },
  disabled: true,
  loading: true,
  registerLoading: true,
  formState: false,
  event: {
    maint_event_id: Number.NaN,
    event_name: "",
    set: undefined,
    subject1: undefined,
    subject2: undefined,
    subject3: undefined,
    scheduled_date: "",
    display_colorcode: "",
    event_description: "",
    message: "",
  },
  maintenanceClassification: {
    set: [],
    subject1: [],
    subject2: [],
    subject3: [],
  },
  datepicker: {
    isShow: false,
    inputDate: undefined,
    displayDate: "",
  },
  images: [],
});

/**
 * computedをひとまとめにする箱
 * (<template>内で使用する場合には calculated.xxxx.valueと書く必要がある)
 */
const calculated = {
  /**
   * 更新ボタンのDisabledに渡す値を算出する
   */
  disableUpdateButton: computed<boolean>(() => {
    // 各入力項目で問題が問題ない場合formStateにはTrueが入る
    return !state.formState || state.disabled;
  }),
  /**
   * プレビュー表示を行う画像の一覧を算出する
   */
  displayImages: computed<Image[]>(() => {
    // isDeletedがTrueのImageオブジェクトは削除対象なので表示しない
    // 画像のIdとしてULIDを採用しているので、先に登録したものが前に来るようにソートする
    return state.images
      .filter((item) => !item.isDelete)
      .sort((x, y) => (x.id > y.id ? 1 : -1));
  }),
};

/**
 * 関数を入れておくための箱
 */
const funcs = {
  /**
   * QueryStringを解析してリダイレクト用のRouteLocationNamedRawを作成する
   */
  create_redirect_location: (): RouteLocationNamedRaw => {
    const query = route.query;

    const referer = query.referer;

    if (referer === "calendar") {
      if (typeof query.yyyymm === "string") {
        return {
          name: "calendar-yyyymm",
          params: {
            yyyymm: query.yyyymm,
          },
        };
      } else {
        return {
          name: "calendar",
        };
      }
    } else if (referer === "maint-task-list") {
      if (typeof query.yyyymmdd === "string") {
        return {
          name: "maint-task-list-yyyymmdd",
          params: {
            yyyymmdd: query.yyyymmdd,
          },
        };
      } else {
        return {
          name: "maint-task-list",
        };
      }
    } else {
      return {
        name: "event-history",
      };
    }
  },
  /**
   * <v-form>配下の入力項目に対するValidation Rule
   */
  rules: {
    letters1_22: [
      (v: string | null) =>
        (v != null && 1 <= v.trim().length && v.trim().length <= 22) ||
        "1文字以上22文字以下で入力して下さい",
    ],
    letters1_3: [
      (v: string | null) =>
        (v != null && 1 <= v.trim().length && v.trim().length <= 3) ||
        "1文字以上3文字以下で入力して下さい",
    ],
    letters0_200: [
      (v: string | null) =>
        (v != null && v.trim().length <= 200) || "200文字以下で入力して下さい",
    ],
    letters0_300: [
      (v: string | null) =>
        (v != null && v.trim().length <= 300) || "300文字以下で入力して下さい",
    ],
    scheduleDate: [
      (v: string | null) =>
        (v != null && dayjs(v).isSameOrAfter(dayjs(), "day")) ||
        "今日以降の日付を入力して下さい",
    ],
  },
  /**
   * <input>のクリックイベントをScriptから叩く
   */
  openInput: () => {
    const elm: HTMLElement | null = document.getElementById("img");
    if (elm == null) return;
    elm.click();
  },
  /**
   * <input>のchangeイベントに渡して、画像を読み込みstate.imagesに追加する
   */
  loadImages: async (event: Event) => {
    const elm = event.target;
    if (!(elm instanceof HTMLInputElement)) return;
    if (elm.files == null || elm.files.length == 0) return;

    const sum =
      elm.files.length + state.images.filter((image) => !image.isDelete).length;
    const limit = 3;
    if (sum <= limit) {
      for (const file of elm.files) {
        // 画像登録APIで使用できるmime-type以外なら処理を行わない
        if (!acceptImageMimeTypes.includes(file.type.toLowerCase())) {
          state.alertSnackBar.title =
            "JPEG画像およびPNG画像以外は登録できません";
          state.alertSnackBar.message = `"${file.name}": ${file.type}`;
          state.alertSnackBar.type = "error";
          state.alertSnackBar.isShow = true;
          continue;
        }
        if (file.size > maxImageSize) {
          state.alertSnackBar.title = "4MBより大きい画像は登録できません";
          state.alertSnackBar.message = `"${
            file.name
          }"は4MBより大きいです。 (${(file.size / 1024 / 1024).toFixed(1)}MB)`;
          state.alertSnackBar.type = "error";
          state.alertSnackBar.isShow = true;
          continue;
        }

        const data_url = await get_base64_data_url(file);
        state.images.push({
          id: ulid(),
          file: file,
          url: data_url,
          type: file.type,
          isDelete: false,
        });
      }
    } else {
      state.alertSnackBar.title = "添付画像数超過";
      state.alertSnackBar.message = `添付可能な画像数は${limit}枚までです。`;
      state.alertSnackBar.type = "error";
      state.alertSnackBar.isShow = true;
      return;
    }
    // <input>をファイルを選択していない状態に戻す
    elm.value = "";
  },
  /**
   * APIとの通信を行うための関数群
   */
  api: {
    /**
     * API getMaintEventを叩く
     * @return {Promise<ApiResponse>} getMaintEventの結果
     */
    get_maint_event: async (): Promise<ApiResponse> => {
      let count = 3;
      let err: any = null;
      while (count !== 0) {
        try {
          const resp = await connectToApi<ApiResponse>({
            url: "/api/getMaintEvent",
            method: "GET",
            params: {
              maint_event_id: prop.maintEventId,
            },
          });
          return resp.data;
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }
      throw err;
    },
    /**
     * API uploadEventImageを叩く
     * @param image {Image} アップロードする画像の情報
     * @return {Promise<SuccessUploadImage>} アップロードに成功したことを示すクラス
     */
    upload_event_image: async (image: Image): Promise<SuccessUploadImage> => {
      let count = 3;
      let err: any = null;
      while (count !== 0) {
        try {
          await connectToApi({
            method: "POST",
            url: `/api/uploadEventImage`,
            headers: {
              "Content-Type": image.type,
            },
            data: image.file,
            params: {
              maint_event_id: prop.maintEventId,
              image_id: image.id,
            },
          });
          return new SuccessUploadImage(image.id);
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }

      console.error("api.upload_event_image", err);
      // APIからメッセージが返ってきていれば、それをエラーインスタンスに渡す
      let message = err?.response?.data?.message;
      if (typeof message !== "string") {
        message = "画像のアップロードに失敗しました";
      }

      let isCompletedOrDeleted = err?.response?.data?.isCompletedOrDeleted;
      if (typeof isCompletedOrDeleted !== "boolean") {
        isCompletedOrDeleted = false;
      }
      throw new UploadImageError(message, isCompletedOrDeleted);
    },
    /**
     * API deleteEventImageを叩く
     * @param image {Image} 削除したい画像の情報
     * @return {Promise<SuccessDeleteImage>} 画像の削除に成功したことを示すクラス
     */
    delete_event_image: async (image: Image): Promise<SuccessDeleteImage> => {
      let count = 3;
      let err: any = null;
      while (count !== 0) {
        try {
          await connectToApi({
            url: `/api/deleteEventImage`,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              maint_event_id: Number(prop.maintEventId),
              image_id: image.id,
              content_type: image.type,
            },
          });
          return new SuccessDeleteImage(image.id);
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }

      console.error("delete_event_image", err);
      // APIからエラーメッセージが返っていれば、それをエラーインスタンスに渡す
      let message = err?.response?.data?.message;
      if (typeof message !== "string") {
        message = "画像の削除に失敗しました";
      }
      let isCompletedOrDeleted = err?.response?.data?.isCompletedOrDeleted;
      if (typeof isCompletedOrDeleted !== "boolean") {
        isCompletedOrDeleted = false;
      }

      throw new DeleteImageError(message, isCompletedOrDeleted);
    },
    /**
     * API updateMaintEventを叩く
     * @param event {EventState} 更新させたいmaint_eventの内容
     */
    update_maint_event: async (event: EventState): Promise<null> => {
      let count = 3;
      let err: any = null;
      while (count !== 0) {
        try {
          await connectToApi({
            method: "POST",
            url: "/api/updateMaintEvent",
            headers: {
              "Content-Type": "application/json",
            },
            data: event,
          });
          return null;
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }

      console.error("update_maint_event", err);
      // APIからエラーメッセージが返ってきていれば、それをエラーインスタンスに渡す
      let message = err?.response?.data?.message;
      if (typeof message !== "string") {
        message = "イベントの更新に失敗しました";
      }

      let isCompletedOrDeleted = err?.response?.data?.isCompletedOrDeleted;
      if (typeof isCompletedOrDeleted !== "boolean") {
        isCompletedOrDeleted = false;
      }

      throw new UpdateEventError(message, isCompletedOrDeleted);
    },
    /**
     * API deleteMaintEventを叩く
     * @param maint_event_id {number} 削除対象のmaint_eventのID
     */
    delete_maint_event: async (maint_event_id: number) => {
      let count = 3;
      let err: any = null;
      while (count !== 0) {
        try {
          await connectToApi({
            method: "POST",
            url: "/api/deleteMaintEvent",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              maint_event_id: maint_event_id,
            },
          });
          return;
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }

      console.error("delete_maint_event", err);
      // APIからエラーメッセージが返っていれば、エラーインスタンスに渡す
      let message = err?.response?.data?.message;
      if (typeof message !== "string") {
        message = "イベントの削除に失敗しました";
      }

      let isCompletedOrDeleted = err?.response?.data?.isCompletedOrDeleted;
      if (typeof isCompletedOrDeleted !== "boolean") {
        isCompletedOrDeleted = false;
      }

      throw new DeleteEventError(message, isCompletedOrDeleted);
    },
  },
  /**
   * 編集対象のイベントの情報を取得する
   */
  get_event: async () => {
    state.loading = true;
    state.registerLoading = true;
    state.disabled = true;

    try {
      const resp = await funcs.api.get_maint_event();

      if (resp.maint_event.completed_date != null) {
        const location = funcs.create_redirect_location();
        location.query = {
          edit: "error",
          edit_target: "event",
          message: "完了または削除されたイベントは編集できません。",
          title: "イベントの編集はできません",
        };
        await router.push(location);
        return;
      }

      const classification = await requestGetMaintenanceClassification(
        storeAuthorities.selectedAuthority.plantId,
      );
      state.maintenanceClassification = {
        set: classification.set,
        subject1: classification.subject1,
        subject2: classification.subject2,
        subject3: classification.subject3,
      };
      state.event = {
        maint_event_id: resp.maint_event.maint_event_id,
        event_name: resp.maint_event.event_name,
        set: resp.maint_event.set,
        subject1: resp.maint_event.subject1,
        subject2: resp.maint_event.subject2,
        subject3: resp.maint_event.subject3,
        scheduled_date: resp.maint_event.scheduled_date,
        display_colorcode: resp.maint_event.display_colorcode,
        event_description: resp.maint_event.event_description,
        message: resp.maint_event.message,
      };
      state.datepicker.inputDate = dayjs(
        resp.maint_event.scheduled_date,
      ).toDate();

      for (const image_url of resp.maint_event.image_urls) {
        const obj_url = new URL(image_url);
        const path = obj_url.pathname;
        const name = basename(path);
        const part = name.split(".");
        state.images.push({
          id: part[0],
          url: image_url,
          type: `image/${part[1]}`,
          isDelete: false,
        });
      }
    } catch (e: any) {
      let message = e?.response?.data?.message;
      if (typeof message !== "string") {
        message = "イベント編集の初期データ取得時に不具合が発生しました。";
      }

      let isCompletedOrDeleted = e?.response?.data?.isCompletedOrDeleted;
      if (typeof isCompletedOrDeleted !== "boolean") {
        isCompletedOrDeleted = false;
      }
      // イベント情報が得られなければ、強制的にリダイレクトして編集画面から出る
      const location = funcs.create_redirect_location();
      location.query = {
        edit: "error",
        edit_target: "event",
        message: message,
        title: isCompletedOrDeleted
          ? "完了もしくは削除されたイベントは編集できません"
          : "イベントの取得に失敗しました",
      };

      await router.push(location);
    } finally {
      state.loading = false;
      state.registerLoading = false;
      state.disabled = false;
    }
  },
  /**
   * イベントを更新する
   */
  update_event: async () => {
    state.disabled = true;
    state.registerLoading = true;
    try {
      // Promiseインスタンスの配列を作る
      const apiAccesses = [
        funcs.api.update_maint_event(state.event),
        ...state.images
          .filter((image) => {
            return image.file != null || image.isDelete;
          })
          .map((image) => {
            if (image.file != null) {
              return funcs.api.upload_event_image(image);
            } else {
              return funcs.api.delete_event_image(image);
            }
          }),
      ];
      // 複数のAPIアクセスを並列で行う
      // 各APIアクセスが成功しようと失敗しようと、全てが終わるまで待つ
      // 失敗しても、例外は投げられない
      const resp: PromiseSettledResult<
        null | SuccessUploadImage | SuccessDeleteImage
      >[] = await Promise.allSettled(apiAccesses);

      // APIアクセスに失敗したものが一つもなければ、更新に成功したと判断してリダイレクトを行う
      const first_rejected_result = resp.find(
        (item) => item.status === "rejected",
      );
      if (first_rejected_result == null) {
        const location = funcs.create_redirect_location();
        location.query = {
          edit: "update",
          edit_target: "event",
        };

        await router.push(location);
        return;
      }

      let msg_err_update_maint_event: string | null = null;
      let msg_err_upload_event_image: string | null = null;
      let msg_err_delete_event_image: string | null = null;

      let flagCompletedOrDeleted = false;
      // APIアクセスに失敗したものが一つ以上あるのでそれを取得する
      // また画像関連のAPIアクセスに成功している分をstate.imagesに反映する
      for (const item of resp) {
        if (isPromiseRejectedResult(item)) {
          // エラーメッセージの集積を行う
          const err = item.reason;
          console.error("reason", err);
          console.error({
            err: err,
            constructor: err.constructor.name,
            "err instanceof UpdateEventError": err instanceof UpdateEventError,
            "err instanceof UploadImageError": err instanceof UploadImageError,
            "err instanceof DeleteImageError": err instanceof DeleteImageError,
            "err instanceof Error": err instanceof Error,
          });
          if (err instanceof UpdateEventError) {
            console.error("UpdateEventError", err);
            msg_err_update_maint_event = err.message;
            if (err.isCompletedOrDeleted) {
              flagCompletedOrDeleted = true;
              break;
            }
          } else if (err instanceof UploadImageError) {
            console.error("UploadImageError", err);
            msg_err_upload_event_image = err.message;
            if (err.isCompletedOrDeleted) {
              flagCompletedOrDeleted = true;
              break;
            }
          } else if (err instanceof DeleteImageError) {
            console.error("DeleteImageError", err);
            msg_err_delete_event_image = err.message;
            if (err.isCompletedOrDeleted) {
              flagCompletedOrDeleted = true;
              break;
            }
          }
        } else {
          const value = item.value;
          if (value instanceof SuccessDeleteImage) {
            // 画像の削除に成功しているので、state.imagesからも削除する
            const index = state.images.findIndex(
              (item) => item.id === value.image_id,
            );
            state.images.splice(index, 1);
          } else if (value instanceof SuccessUploadImage) {
            // 画像の追加に成功しているので、state.imagesから１度削除して、APIから取得した画像としてstate.imagesに追加する
            const index = state.images.findIndex(
              (item) => item.id === value.image_id,
            );
            const image = state.images.splice(index, 1)[0];
            state.images.push({
              id: image.id,
              url: image.url,
              type: image.type,
              isDelete: false,
            });
          }
        }
      }

      if (flagCompletedOrDeleted) {
        const location = funcs.create_redirect_location();
        location.query = {
          edit: "error",
          edit_target: "event",
          message: "完了したもしくは削除されたイベントは編集できません。",
          title: "イベントの更新に失敗しました",
        };
        await router.push(location);
        return;
      }

      // AlertSnackBarに表示するエラーメッセージを組み立てる
      let count = 1;
      let message = "";
      for (const msg of [
        msg_err_update_maint_event,
        msg_err_upload_event_image,
        msg_err_delete_event_image,
      ]) {
        if (msg == null) continue;
        message += `${count}. ${msg} `;
        count += 1;
      }
      state.alertSnackBar.message = message;
      state.alertSnackBar.title = "イベントの更新に失敗しました";
      state.alertSnackBar.type = "error";

      state.alertSnackBar.isShow = true;
    } finally {
      state.disabled = false;
      state.registerLoading = false;
    }
  },
  /**
   * イベントの削除を行う
   */
  delete_event: async () => {
    const confirmMessage = "イベントを削除してもよろしいでしょうか？";
    const confirmed = confirm(confirmMessage);
    if (!confirmed) {
      return;
    }

    state.disabled = true;
    state.registerLoading = true;
    try {
      await funcs.api.delete_maint_event(state.event.maint_event_id);

      const location = funcs.create_redirect_location();
      location.query = {
        edit: "delete",
        edit_target: "event",
      };
      await router.push(location);
    } catch (e: any) {
      state.alertSnackBar.title = "イベントの削除に失敗しました";
      state.alertSnackBar.message = "";
      if (e instanceof DeleteEventError) {
        state.alertSnackBar.message = e.message;

        if (e.isCompletedOrDeleted) {
          const location = funcs.create_redirect_location();
          location.query = {
            edit: "error",
            edit_target: "event",
            message: "完了または削除されたイベントは削除することはできません。",
            title: "イベントの削除に失敗しました",
          };
          await router.push(location);
          return;
        }
      } else if (e instanceof Error) {
        state.alertSnackBar.message = e.message;
      } else if (typeof e?.message === "string") {
        state.alertSnackBar.message = e.message;
      }

      state.alertSnackBar.isShow = true;
    } finally {
      state.disabled = false;
      state.registerLoading = false;
    }
  },
  /**
   * 画像のプレビューにおいて、削除ボタンを押した時の処理
   * @param image
   */
  delete_preview: (image: Image) => {
    const index = state.images.findIndex((item) => item.id == image.id);
    if (index === -1) return;
    if (image.file == null) {
      // API から取得した画像であるので削除フラグを立てる
      image.isDelete = true;
      state.images[index] = image;
    } else {
      // 追加しようとしていた画像なので、state.imagesから削除する
      state.images.splice(index, 1);
    }
  },
};

// v-datepickerにおいて値が更新されたら、表示用の値とstate.event.scheduled_dateを更新する
watch(
  () => state.datepicker.inputDate,
  () => {
    if (!state.datepicker.inputDate) {
      state.datepicker.displayDate = "";
      state.event.scheduled_date = "";
      return;
    }
    const d = dayjs(state.datepicker.inputDate);
    state.datepicker.displayDate = d.format("YYYY/MM/DD");
    state.event.scheduled_date = d.format("YYYYMMDD");
  },
);

// イベントデータを取得する
funcs.get_event();
</script>

<style scoped>
.input-field-22c {
  width: 400px;
}
.input-field-3c {
  max-width: 150px;
}
.input-field-color {
  max-width: 155px;
}
.datepicker-textfield {
  width: 180px;
}
.img_area {
  border: 1px dashed #a19a9a;
  height: 100px;
}
.complement_font {
  color: #6b6d75;
}
a.cancel-btn::before {
  opacity: 0;
}
</style>
