import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { createI18n } from "vue-i18n";
import { createPinia } from "pinia";
import dayjs from "dayjs";
import { setAutoCheckMaintenanceMode } from "@/helpers/maintainance_mode";

setAutoCheckMaintenanceMode();
const app = createApp(App);

// routerのbeforeEach()に設定しているauthGuardの中でpiniaを使用しているので、先に有効化する必要がある
app.use(createPinia());
app.use(router);
app.use(vuetify);

// ロケール設定
import "dayjs/locale/ja"; // ロケールのインポート
dayjs.locale("ja");
app.config.globalProperties.$dayjs = dayjs;

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $dayjs: typeof dayjs;
  }
}

// GoogleAnalytics設定
import VueGtag from "vue-gtag";
app.use(
  VueGtag,
  {
    config: {
      id: import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID!,
    },
  },
  router,
);

import common from "@/assets/translation/common.json";
import top from "@/assets/translation/top.json";
import releasenote from "@/assets/translation/releasenote.json";
import others from "@/assets/translation/others.json";

import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "08217a9b-1783-4aea-8a41-2d02f6dc0461",
  clientToken: "pubd355ec0b23019613813bf235513612ec",
  site: "datadoghq.com",
  service: "e-maint.",
  env: import.meta.env.VITE_APP_MODE,
  version: __PACKAGE_VERSION__,
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask",
});
/*
data-dd-action-name属性でクリック時などのアクション名を指定可能なので、秘匿すべき箇所などに指定する
参考: https://docs.datadoghq.com/ja/real_user_monitoring/browser/tracking_user_actions/
*/
datadogRum.startSessionReplayRecording();

const i18n = createI18n({
  legacy: false,
  locale: "ja",
  messages: {
    en: {
      common: common.en,
      top: top.en,
      releasenote: releasenote.en,
      others: others.en,
    },
    ja: {
      common: common.ja,
      top: top.ja,
      releasenote: releasenote.ja,
      others: others.ja,
    },
  },
});
app.use(i18n);

app.mount("#app");
