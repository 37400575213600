<template>
  <v-container>
    <alert-snack-bar
      v-model="state.popSnackBar"
      :type="state.snackBarType"
      :title="state.snackBarTitle"
      :message="state.snackBarMessage"
    />
    <v-row>
      <v-col cols="12">
        <v-row align-content="center" class="mt-2">
          <v-col cols="12" class="d-inline-flex justify-end">
            <h3>イベント一覧</h3>
            <v-spacer />
            <v-text-field
              v-model="search"
              color="primary"
              variant="outlined"
              density="compact"
              label="キーワードで検索（例：清掃）"
              class="mx-2 input-field-search"
            ></v-text-field>
            <v-btn
              v-if="isPossibleUser"
              color="primary"
              class="mx-2"
              to="/edit-maint-event?referrer=event-history"
            >
              <v-icon start> mdi-plus </v-icon>
              イベントの新規追加
            </v-btn>
          </v-col>
        </v-row>
        <v-spacer />
        <v-sheet color="white" elevation="1">
          <v-data-table
            v-model="state.selected"
            density="comfortable"
            :headers="headers"
            hover
            :items="viewData"
            :items-per-page="10"
            :loading="isRequestingApi ? 'primary' : false"
            :page="page"
            items-per-page-text="行/ページ:"
            :search="search"
            return-object
            no-data-text="イベントなし"
            class="elevation-0 event-history maint-event-row"
            @update:page="updatePageQueryString"
          >
            <template #[`header.event_name`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterName.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="set in Array.from(
                      new Set(
                        apiResponse.maintEvents.map(
                          (maintEvent) => maintEvent.event_name,
                        ),
                      ),
                    ).sort()"
                    :key="set"
                    v-model="state.filterName"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="set"
                    :value="set"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterName = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`header.set`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterSet.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="set in Array.from(
                      new Set(
                        apiResponse.maintEvents.map(
                          (maintEvent) => maintEvent.set,
                        ),
                      ),
                    ).sort()"
                    :key="set"
                    v-model="state.filterSet"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="set"
                    :value="set"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterSet = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`header.subject1`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterSubject1.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="subject1 in Array.from(
                      new Set(
                        apiResponse.maintEvents.map(
                          (maintEvent) => maintEvent.subject1,
                        ),
                      ),
                    ).sort()"
                    :key="subject1"
                    v-model="state.filterSubject1"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="subject1"
                    :value="subject1"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterSubject1 = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`header.subject2`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterSubject2.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="subject2 in Array.from(
                      new Set(
                        apiResponse.maintEvents.map(
                          (maintEvent) => maintEvent.subject2,
                        ),
                      ),
                    ).sort()"
                    :key="subject2"
                    v-model="state.filterSubject2"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="subject2"
                    :value="subject2"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterSubject2 = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`header.subject3`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterSubject3.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="subject3 in Array.from(
                      new Set(
                        apiResponse.maintEvents.map(
                          (maintEvent) => maintEvent.subject3,
                        ),
                      ),
                    ).sort()"
                    :key="subject3"
                    v-model="state.filterSubject3"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="subject3"
                    :value="subject3"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterSubject3 = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`item.event_name`]="{ item }">
              <td
                class="dummy-link"
                @click="openEventDetail(item.maint_event_id)"
              >
                <span class="dummy-link">{{ item.event_name }}</span>
                <v-icon v-if="item.having_images"> mdi-paperclip </v-icon>
              </td>
            </template>
            <template #[`item.event_description`]="{ item }">
              <td class="text-truncate" style="max-width: 27rem">
                {{ item.event_description }}
              </td>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <event-detail
      :event-detail-open-status="state.eventDetailOpenStatus"
      :maint-event="state.selectedMaintEvent"
      @open-status-update-request="updateReleaseNoteStatusHandler"
      @snack-bar="snackBarHandler"
      @need-refresh="refreshHandler"
    />
    <overlay-loading :is-loading="isLoading" />
  </v-container>
</template>

<script setup lang="ts">
import { reactive, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import { connectToApi } from "@/helpers/connectToApi";
import AlertSnackBar from "@/components/AlertSnackBar.vue";
import EventDetail from "@/components/EventDetail.vue";
import { MaintEvent, MaintEventData } from "@/models/MaintEvent";
import OverlayLoading from "@/components/OverlayLoading.vue";
import { useAuthoritiesStore } from "@/stores/authorities";
import { AlertSnackBarModel } from "@/models/snackbar";

type MaintEventRecord = {
  id: number;
  maint_event_id: number;
  event_name: string;
  set: string;
  subject1: string;
  subject2: string;
  subject3: string;
  display_colorcode: string;
  scheduled_date: string;
  event_description: string;
  message: string;
  completed_date: string;
  unread: boolean;
  having_images: boolean;
};

type ApiResponse = {
  maintEvents: MaintEventRecord[];
};

type ApiDetailResponse = {
  maint_event: MaintEventData;
};

type StateDatePicker = {
  isShow: boolean;
  inputDate: Date | undefined;
  displayDate: string;
};

type TableFilter = {
  filterName: string[];
  filterSet: string[];
  filterSubject1: string[];
  filterSubject2: string[];
  filterSubject3: string[];
  selected: MaintEventRecord[];
  popSnackBar: boolean;
  snackBarType: string;
  snackBarTitle: string;
  snackBarMessage: string;
  datepicker: StateDatePicker;
  isRequestingApi: boolean;
  eventDetailOpenStatus: boolean;
  selectedMaintEvent: MaintEvent;
};

const storeAuthorities = useAuthoritiesStore();
const route = useRoute();
const router = useRouter();

const getEventHistory = async (): Promise<ApiResponse> => {
  const response = await connectToApi<ApiResponse>({
    method: "GET",
    url: "/api/getEventHistory",
    params: {
      plant_id: storeAuthorities.selectedAuthority.plantId,
    },
  }).catch((e) => {
    state.snackBarType = "error";
    state.snackBarTitle = "データの更新に失敗しました。";
    state.snackBarMessage =
      e.response !== undefined
        ? e.response.data.message
        : "データの更新に関する不具合が発生しています。";
    state.popSnackBar = true;
    throw e;
  });

  response.data.maintEvents.forEach((item) => {
    item.scheduled_date = dayjs(item.scheduled_date).format("YYYY/MM/DD");
    if (item.completed_date != null || item.completed_date != undefined) {
      item.completed_date = dayjs(item.completed_date).format("YYYY/MM/DD");
    }
  });
  return response.data;
};

const apiResponse = reactive<ApiResponse>({
  maintEvents: [],
});

const viewData = computed(() => {
  let returnData = apiResponse.maintEvents;

  if (state.filterName.length) {
    returnData = returnData.filter((maintEvent) =>
      state.filterName.includes(maintEvent.event_name),
    );
  }
  if (state.filterSet.length) {
    returnData = returnData.filter((maintEvent) =>
      state.filterSet.includes(maintEvent.set),
    );
  }
  if (state.filterSubject1.length) {
    returnData = returnData.filter((maintEvent) =>
      state.filterSubject1.includes(maintEvent.subject1),
    );
  }
  if (state.filterSubject2.length) {
    returnData = returnData.filter((maintEvent) =>
      state.filterSubject2.includes(maintEvent.subject2),
    );
  }
  if (state.filterSubject3.length) {
    returnData = returnData.filter((maintEvent) =>
      state.filterSubject3.includes(maintEvent.subject3),
    );
  }

  returnData.forEach((data, index) => {
    //noは行番号にする。vslot使う場合はrowしかとれず、二ページ目以降も0から始まってしまうので値を作ってセットする
    data.id = index + 1;
    // 画像添付アイコンの有無を設定
  });

  return returnData;
});

const isRequestingApi = ref(false);
const page = ref(1);
const search = ref<string>("");
const isPossibleUser = ref(true);
const isLoading = ref<boolean>(false);

const state = reactive<TableFilter>({
  filterName: [],
  filterSet: [],
  filterSubject1: [],
  filterSubject2: [],
  filterSubject3: [],
  selected: [],
  popSnackBar: false,
  snackBarType: "error",
  snackBarTitle: "データの更新に失敗しました。",
  snackBarMessage: "データの更新に関する不具合が発生しています。",
  datepicker: {
    isShow: false,
    inputDate: undefined,
    displayDate: "",
  },
  isRequestingApi: false,
  eventDetailOpenStatus: false,
  selectedMaintEvent: MaintEvent.dummy(),
});
let startPage: number | null = null;

const getPageFromQueryString = (): number | null => {
  const raw = route.query.page;
  const num = Number(raw);
  return Number.isNaN(num) ? null : num;
};

const loadEventHistory = async () => {
  setStartPage();
  await displayEventHistory();
};

const setStartPage = () => {
  startPage = getPageFromQueryString();
};

const displayEventHistory = async () => {
  isRequestingApi.value = true;
  const data: ApiResponse = await getEventHistory();
  apiResponse.maintEvents = data.maintEvents;
  if (startPage != null) {
    page.value = startPage;
    startPage = null;
  }
  isRequestingApi.value = false;
};

const updatePageQueryString = (currentPage: number) => {
  router.push({
    query: {
      page: currentPage.toString(),
    },
  });
  page.value = currentPage;
};

const getEventDetail = async (
  maint_event_id: number,
): Promise<ApiDetailResponse> => {
  const resp = await connectToApi<ApiDetailResponse>({
    url: "/api/getMaintEvent",
    method: "GET",
    params: {
      maint_event_id: maint_event_id,
    },
  });
  return resp.data;
};

// イベント詳細モーダルを開く
const openEventDetail = async (maint_event_id: number): Promise<void> => {
  try {
    isLoading.value = true;
    const data = await getEventDetail(maint_event_id);
    state.selectedMaintEvent = new MaintEvent(data.maint_event);

    state.eventDetailOpenStatus = true;
  } catch (e: any) {
    const message =
      e?.response?.data?.message || "イベント詳細の取得に失敗しました。";
    state.snackBarType = "error";
    state.snackBarTitle = "データの取得に失敗しました。";
    state.snackBarMessage = message;
    state.popSnackBar = true;
  } finally {
    isLoading.value = false;
  }
};

const updateReleaseNoteStatusHandler = (status: boolean | undefined): void => {
  if (status !== undefined) {
    state.eventDetailOpenStatus = status;
  }
};

/**
 * 子ComponentからEmitされたSnackBarを表示
 */
const snackBarHandler = (snackBar: AlertSnackBarModel | undefined) => {
  if (snackBar !== undefined) {
    state.popSnackBar = true;
    state.snackBarType = snackBar.type;
    state.snackBarTitle = snackBar.title;
    state.snackBarMessage = snackBar.message;
  }
};

/**
 * 子Componentから、リフレッシュが必要というEmitを受けたらリフレッシュ
 */
const refreshHandler = (needRefresh: boolean | undefined) => {
  if (needRefresh !== undefined) {
    if (needRefresh) {
      loadEventHistory();
    }
  }
};

const headers = computed(() => {
  const base = [
    { title: "No.", key: "id", width: "3%" },
    { title: "イベント名", key: "event_name", width: "20%" },
    { title: "セット", key: "set", width: "8%" },
    { title: "対象1", key: "subject1", width: "6%" },
    { title: "対象2", key: "subject2", width: "6%" },
    { title: "対象3", key: "subject3", width: "6%" },
    { title: "計画日", key: "scheduled_date", width: "6%" },
    { title: "完了日", key: "completed_date", width: "6%" },
    { title: "イベントの説明", key: "event_description", width: "27%" },
  ];

  return base;
});

onMounted(async () => {
  // 新規イベント追加画面からクエリパラメータが着ていないか確認する
  if (Object.keys(route.query).length) {
    const query = Object.assign({}, route.query);
    state.snackBarType = "success";
    state.snackBarTitle = "";
    if (query["edit"] == "create") {
      state.snackBarMessage = "新規イベントを登録しました。";
    } else if (query["edit"] == "error") {
      state.snackBarTitle = "新規イベントの登録時に不具合が発生しました。";
      // イベント編集画面でイベント情報取得失敗時にも使用したいので、任意のタイトルを使用できるようにした
      if (typeof query.title === "string") {
        state.snackBarTitle = query.title;
      }
      const msg =
        typeof query["message"] == "string"
          ? query["message"]
          : "データの更新に関する不具合が発生しています。";
      state.snackBarMessage = msg;
      state.snackBarType = "error";
    } else if (query.edit === "update") {
      state.snackBarMessage = "イベントを更新しました。";
    } else if (query.edit === "delete") {
      state.snackBarMessage = "イベントを削除しました。";
    } else {
      state.snackBarMessage = "不明なオペレーションです";
    }

    state.popSnackBar = true;

    // AlertSnackBarの表示に使用したQueryStringを削除する
    if (query?.edit != null || query?.message != null || query?.title != null) {
      delete query.edit;
      delete query.message;
      delete query.title;
      await router.replace({ query });
    }
  }
  isPossibleUser.value =
    storeAuthorities.roleChecker.isUserAdmin ||
    storeAuthorities.roleChecker.isOperator;
  await loadEventHistory();
});
</script>

<style scoped>
:deep(.event-history.v-data-table table thead tr th) {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700;
}
:deep(.event-history .v-data-table-rows-loading) {
  color: rgba(0, 0, 0, 0.38);
}
:deep(.event-history .v-data-table-rows-no-data) {
  color: rgba(0, 0, 0, 0.38);
}
.input-field-search {
  width: 500px;
  max-width: 500px !important;
}

td.dummy-link {
  cursor: pointer;
}

span.dummy-link {
  margin-right: 0.5rem;
}

.maint-event-row .v-data-table__tr:hover td.dummy-link span.dummy-link {
  text-decoration: underline;
  color: blue;
}
</style>
