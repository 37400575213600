<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <alert-snack-bar
        v-model="state.snackBar.isShow"
        :type="state.snackBar.type"
        :title="state.snackBar.title"
        :message="state.snackBar.message"
      />
      <v-row>
        <v-col xl="12" cols="12" class="d-inline-flex">
          <h3 class="my-3">イベントの新規追加</h3>
          <v-spacer />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-sheet color="white" elevation="1" class="pa-2">
            <v-row>
              <v-col xl="12" cols="12">
                <v-text-field
                  v-model="state.MaintEvent.event_name"
                  color="primary"
                  density="compact"
                  persistent-counter
                  :rules="rule22"
                  counter="22"
                  label="イベント名"
                  :loading="state.loading"
                  variant="outlined"
                  class="input-field-22c"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-inline-flex justify-start">
                <v-combobox
                  v-model="state.MaintEvent.set"
                  color="primary"
                  density="compact"
                  :items="comboBoxItems.setItems"
                  persistent-counter
                  :rules="rule3"
                  counter="3"
                  label="セット"
                  :loading="state.loading || state.comboboxLoading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-combobox>
                <v-combobox
                  v-model="state.MaintEvent.subject1"
                  color="primary"
                  density="compact"
                  :items="comboBoxItems.subject1Items"
                  persistent-counter
                  :rules="rule3"
                  counter="3"
                  label="対象1"
                  :loading="state.loading || state.comboboxLoading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-combobox>
                <v-combobox
                  v-model="state.MaintEvent.subject2"
                  color="primary"
                  density="compact"
                  :items="comboBoxItems.subject2Items"
                  persistent-counter
                  :rules="rule3"
                  counter="3"
                  label="対象2"
                  :loading="state.loading || state.comboboxLoading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-combobox>
                <v-combobox
                  v-model="state.MaintEvent.subject3"
                  color="primary"
                  density="compact"
                  :items="comboBoxItems.subject3Items"
                  persistent-counter
                  :rules="rule3"
                  counter="3"
                  label="対象3"
                  :loading="state.loading || state.comboboxLoading"
                  variant="outlined"
                  class="input-field-3c"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2"></v-divider>
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">計画日</h4>
                <v-menu
                  v-model="state.datepicker.isShow"
                  :close-on-content-click="false"
                  :offset="40"
                  location="bottom"
                  min-width="auto"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      v-bind="props"
                      v-model="state.datepicker.displayDate"
                      class="datepicker-textfield input-shift"
                      :loading="state.loading"
                      append-inner-icon="mdi-calendar"
                      color="primary"
                      density="compact"
                      readonly
                      variant="outlined"
                      :rules="ruleSchedule"
                    />
                  </template>
                  <v-locale-provider locale="ja">
                    <v-date-picker
                      v-model="state.datepicker.inputDate"
                      color="primary"
                      header=""
                      :title="
                        $dayjs(state.datepicker.inputDate).format('YYYY年')
                      "
                      class="elevation-1"
                      @update:model-value="state.datepicker.isShow = false"
                    />
                  </v-locale-provider>
                </v-menu>
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2"></v-divider>
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">表示色</h4>
                <v-select
                  v-model="state.MaintEvent.display_colorcode"
                  chips
                  color="primary"
                  density="compact"
                  :items="colorItems"
                  variant="outlined"
                  class="input-field-color"
                >
                  <template #chip="{ item, props }">
                    <v-avatar
                      v-bind="props"
                      :color="item.value"
                      size="x-small"
                    />
                  </template>
                  <template #item="{ item, props }">
                    <v-list-item
                      v-bind="props"
                      color="primary"
                      density="compact"
                    >
                      <template #title>
                        <v-avatar :color="item.value" size="x-small" />
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">補足情報</h4>
                <h5 class="mb-2 complement_font">
                  イベントの説明（200文字まで）
                </h5>
                <v-textarea
                  v-model="state.MaintEvent.event_description"
                  color="primary"
                  label="テキストの入力"
                  counter="200"
                  persistent-counter
                  :rules="rule200"
                  variant="outlined"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4 class="mb-5">写真の添付 (最大3つ)</h4>
                <v-btn
                  class="img_area"
                  block
                  height="100px"
                  variant="outlined"
                  @click="openInput"
                >
                  <v-icon class="my-2" alt="search icon" size="x-large">
                    mdi-plus
                  </v-icon>
                </v-btn>
                <p>
                  <input
                    id="img"
                    ref="input"
                    type="file"
                    accept="image/jpeg, image/png"
                    style="display: none"
                    multiple
                    @change="load_image"
                  />
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(previewFile, index) in previewFiles.imageUrl"
                :key="index"
              >
                <img
                  v-if="previewFile != null"
                  :src="previewFile"
                  alt="tmp"
                  style="max-width: 300px; border: solid 1px black"
                />
                <v-btn
                  class="mx-2"
                  icon
                  size="x-small"
                  variant="tonal"
                  @click="deletePreview(index)"
                >
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="12" cols="12">
                <h5 class="mb-2 complement_font">申送事項（300文字まで）</h5>
                <v-textarea
                  v-model="state.MaintEvent.message"
                  color="primary"
                  label="テキストの入力"
                  counter="300"
                  persistent-counter
                  :rules="rule300"
                  variant="outlined"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="12" cols="12" class="d-flex justify-end">
                <v-btn
                  variant="outlined"
                  class="mr-4"
                  :to="createRedirectLocation()"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  variant="flat"
                  color="primary"
                  class="mr-4"
                  :loading="state.registering"
                  @click="registerMaintEvent"
                >
                  登録
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import { connectToApi } from "@/helpers/connectToApi";
import AlertSnackBar from "@/components/AlertSnackBar.vue";
import { get_base64_data_url } from "@/helpers/get_base64_data_url_from_file";
import { ulid } from "@/helpers/ulid";
import { AlertSnackBarModel } from "@/models/snackbar";
import {
  colorItems,
  maxImageSize,
  acceptImageMimeTypes,
} from "@/models/MaintEvent";
import { useAuthoritiesStore } from "@/stores/authorities";
import { requestGetMaintenanceClassification } from "@/helpers/api/getMaintenanceClassification";

type MaintEvent = {
  maint_event_id: number;
  plant_id: number;
  event_name: string;
  set: string | undefined;
  subject1: string | undefined;
  subject2: string | undefined;
  subject3: string | undefined;
  scheduled_date: string;
  display_colorcode: string;
  event_description: string;
  message: string;
};
type StateDatePicker = {
  isShow: boolean;
  inputDate: Date | undefined;
  displayDate: string;
};
type StateComboBoxItems = {
  setItems: string[];
  subject1Items: string[];
  subject2Items: string[];
  subject3Items: string[];
};

type State = {
  MaintEvent: MaintEvent;
  snackBar: AlertSnackBarModel;
  loading: boolean;
  registering: boolean;
  deleting: boolean;
  comboboxLoading: boolean;
  datepicker: StateDatePicker;
};

type ApiResponse = {
  maint_event: MaintEvent;
};

type Preview = {
  imageUrl: string[];
};

type Upload = {
  imageUrl: File[];
};

type LocationData = {
  path: string;
  query: { [key: string]: string };
};

const storeAuthorities = useAuthoritiesStore();
const route = useRoute();
const router = useRouter();

const state = reactive<State>({
  MaintEvent: {
    maint_event_id: -1,
    plant_id: -1,
    event_name: "",
    set: undefined,
    subject1: undefined,
    subject2: undefined,
    subject3: undefined,
    scheduled_date: "",
    display_colorcode: "#00A82F",
    event_description: "",
    message: "",
  },
  snackBar: {
    isShow: false,
    type: "error",
    title: "データの更新に失敗しました。",
    message: "データの更新に関する不具合が発生しています。",
  },
  loading: false,
  registering: false,
  deleting: false,
  comboboxLoading: false,
  datepicker: {
    isShow: false,
    inputDate: undefined,
    displayDate: "",
  },
});

const input = ref();

// ファイル選択画面を開く
const openInput = () => {
  input.value.click();
};

const previewFiles = reactive<Preview>({
  imageUrl: [],
});

const uploadFiles = reactive<Upload>({
  imageUrl: [],
});

// プレビュー表示用＋アップロード用にファイルを格納する
const load_image = async (event: Event) => {
  const selectedFile = event.target;
  if (!(selectedFile instanceof HTMLInputElement)) return;
  if (selectedFile.files == null || selectedFile.files.length == 0) {
    previewFiles.imageUrl = [];
    return;
  }

  const sum = selectedFile.files.length + previewFiles.imageUrl.length;
  const limit = 3;
  if (sum <= limit) {
    for (const file of selectedFile.files) {
      if (!acceptImageMimeTypes.includes(file.type.toLowerCase())) {
        state.snackBar.title = "JPEG画像およびPNG画像以外は登録できません";
        state.snackBar.message = `"${file.name}": ${file.type}`;
        state.snackBar.type = "error";
        state.snackBar.isShow = true;
        continue;
      }
      if (file.size > maxImageSize) {
        state.snackBar.title = "4MBより大きい画像は登録できません";
        state.snackBar.message = `"${file.name}"は4MBより大きいです。 (${(
          file.size /
          1024 /
          1024
        ).toFixed(1)}MB)`;
        state.snackBar.type = "error";
        state.snackBar.isShow = true;
        continue;
      }
      try {
        const result = await get_base64_data_url(file);
        previewFiles.imageUrl.push(result);
        uploadFiles.imageUrl.push(file);
      } catch (e) {
        alert(`ERROR: ${e}`);
      }
    }
  } else {
    state.snackBar.title = "添付画像数超過";
    state.snackBar.message = `添付可能な画像数は${limit}枚までです。`;
    state.snackBar.type = "error";
    state.snackBar.isShow = true;
    return;
  }
  selectedFile.value = "";
};

// 画像プレビューから削除する
const deletePreview = (index: number): void => {
  // 画像プレビューから削除
  previewFiles.imageUrl.splice(index, 1);
  // アップロードファイル格納用データから削除
  uploadFiles.imageUrl.splice(index, 1);
};

const form = ref(null);
const valid = ref(false);
const rule22 = [
  (v: string | null) =>
    (v != null && v.trim().length <= 22 && v.trim().length >= 1) ||
    "1文字以上22文字以下で入力して下さい",
];

const rule3 = [
  (v: string | null) =>
    v == null || v.trim().length <= 3 || "3文字以下で入力して下さい",
];

const rule200 = [
  (v: string | null) =>
    v == null || v.trim().length <= 200 || "200文字以下で入力して下さい",
];

const rule300 = [
  (v: string | null) =>
    v == null || v.trim().length <= 300 || "300文字以下で入力して下さい",
];

const checkScheduledDay = (v: string) => {
  if (v) {
    return dayjs(v).isAfter(dayjs().subtract(1, "d").endOf("day"));
  } else return false;
};
const ruleSchedule = [
  (v: string) => checkScheduledDay(v) || "今日以降の日付を入力して下さい",
];

/**
 * イベント作成後のリダイレクトに使用するLocationDataオブジェクトを作成する
 */
const createRedirectLocation = (): LocationData => {
  // RouteLocationRawではなくLocationDataとしたのは、queryを必須項目にしたかったため
  // エラー時などにqueryでリダイレクト先にAlertSnackBarの表示内容を渡すので
  const location = {
    path: "/event-history",
    query: {},
  };

  const query = route.query;
  const referer = query.referer;

  if (referer === "calendar") {
    location.path = "/calendar";
    if (query.yyyymm != null) {
      location.path += `/${query.yyyymm}`;
    }
  }

  return location;
};

watch(
  () => state.datepicker.inputDate,
  () => {
    let result = "";
    if (state.datepicker.inputDate) {
      result = dayjs(state.datepicker.inputDate).format("YYYY/MM/DD");
      state.MaintEvent.scheduled_date = dayjs(
        state.datepicker.inputDate,
      ).format("YYYYMMDD");
    }
    state.datepicker.displayDate = result;
  },
);

// 画像データをS3へアップロード
const postImage = async (response: any) => {
  // 登録APIのレスポンスからイベントIDを取得
  const maint_event_id = response.data.maint_event.maint_event_id;
  const elm = document.getElementById("img");
  if (elm == null) return;
  if (elm instanceof HTMLInputElement) {
    if (uploadFiles.imageUrl == null || uploadFiles.imageUrl.length == 0)
      return;
    for (let i = 0; i < uploadFiles.imageUrl.length; i++) {
      const file: File = uploadFiles.imageUrl[i];
      // ULIDを使用してIDを作成
      const image_id = ulid();
      await connectToApi({
        method: "POST",
        url: "/api/uploadEventImage",
        data: file,
        headers: {
          "Content-Type": file.type,
        },
        params: {
          maint_event_id: maint_event_id,
          image_id: image_id,
        },
      });
    }
  }
};

const registerMaintEvent = () => {
  if (!valid.value) {
    if (form.value) {
      (form.value as HTMLFormElement & { validate: () => boolean }).validate();
      state.snackBar.type = "warning";
      state.snackBar.title = "登録できませんでした";
      state.snackBar.message = "入力した値を確認して下さい";
      state.snackBar.isShow = true;
      return;
    }
  }
  state.registering = true;

  const callCreateMaintEvent = (MaintEvent: MaintEvent) =>
    connectToApi<ApiResponse>({
      method: "POST",
      url: "/api/createMaintEvent",
      data: {
        plant_id: storeAuthorities.selectedAuthority.plantId,
        event_name: MaintEvent.event_name.trim(),
        set:
          MaintEvent.set == null || MaintEvent.set.trim() == ""
            ? "-"
            : MaintEvent.set.trim(),
        subject1:
          MaintEvent.subject1 == null || MaintEvent.subject1.trim() == ""
            ? "-"
            : MaintEvent.subject1.trim(),
        subject2:
          MaintEvent.subject2 == null || MaintEvent.subject2.trim() == ""
            ? "-"
            : MaintEvent.subject2.trim(),
        subject3:
          MaintEvent.subject3 == null || MaintEvent.subject3.trim() == ""
            ? "-"
            : MaintEvent.subject3.trim(),
        scheduled_date: MaintEvent.scheduled_date,
        display_colorcode: MaintEvent.display_colorcode,
        event_description: MaintEvent.event_description,
        message: MaintEvent.message,
      },
    });

  // 新規追加
  const response = callCreateMaintEvent(state.MaintEvent);
  response
    .then((success) => {
      // S3へ画像のアップロード
      const location = createRedirectLocation();
      const response = postImage(success);
      response
        .then(() => {
          location.query.edit = "create";
          router.push(location);
          state.registering = false;
        })
        .catch(() => {
          const message =
            "イベントは登録されましたが、画像の登録時に不具合が発生したので編集画面より再度登録して下さい。";
          location.query.edit = "error";
          location.query.message = message;
          router.push(location);
          state.registering = false;
        });
    })
    .catch((e) => {
      // エラーのときはチェックボックスを未完了状態に戻しておく
      state.snackBar.type = "error";
      state.snackBar.title = "データの更新に失敗しました。";
      state.snackBar.message =
        e.response !== undefined
          ? e.response.data.message
          : "データの更新に関する不具合が発生しています。";
      state.snackBar.isShow = true;
      state.registering = false;
    });
};

// オートコンプリートのネタ用
const getMaintenanceClassification = async () => {
  state.comboboxLoading = true;
  try {
    const classification = await requestGetMaintenanceClassification(
      storeAuthorities.selectedAuthority.plantId,
    );
    comboBoxItems.setItems = classification.set;
    comboBoxItems.subject1Items = classification.subject1;
    comboBoxItems.subject2Items = classification.subject2;
    comboBoxItems.subject3Items = classification.subject3;
  } catch (e: any) {
    state.snackBar.type = "error";
    state.snackBar.title = "データの取得に失敗しました。";
    state.snackBar.message =
      e.response !== undefined
        ? e.response.data.message
        : "データの取得に関する不具合が発生しています。";
    state.snackBar.isShow = true;
  } finally {
    state.comboboxLoading = false;
  }
};

const comboBoxItems = reactive<StateComboBoxItems>({
  setItems: [],
  subject1Items: [],
  subject2Items: [],
  subject3Items: [],
});

const init = async () => {
  getMaintenanceClassification();
};

init();
</script>

<style scoped>
.input-field-22c {
  width: 400px;
}
.input-field-3c {
  max-width: 150px;
}
.datepicker-textfield {
  width: 180px;
}
.input-field-color {
  max-width: 155px;
}
.img_area {
  border: 1px dashed #a19a9a;
}
.complement_font {
  color: #6b6d75;
}
</style>
