import dayjs, { Dayjs } from "dayjs";

export const colorItems = [
  "#00A82F",
  "#A86F00",
  "#A500A8",
  "#0011A8",
  "#FF8A01",
  "#A81E00",
];

// maint_image_id=42, image_id=01GGFC2T5ZQA3WJ631750G9VC5 のとき、
// 4715622 byteより大きいとCloudFrontがPost Requestを受け付けてくれなかった。
// 4715622 / 1024 / 1024 = 4.50 であったため、きりのよいの4MBを上限とした。
/**
 * 画像の最大サイズ (byte)
 */
export const maxImageSize = 4194304; // 4 * 1024 * 1024

/**
 * 画像で許容されているmime-type
 */
export const acceptImageMimeTypes = ["image/png", "image/jpeg"];

export type MaintEventData = {
  /**
   * ID
   */
  maint_event_id: number;
  /**
   * 作業名
   */
  event_name: string;
  /**
   * セット
   */
  set: string;
  /**
   * 対象1
   */
  subject1: string;
  /**
   * 対象2
   */
  subject2: string;
  /**
   * 対象3
   */
  subject3: string;
  /**
   * 実施日
   */
  scheduled_date: string;
  /**
   * 完了日
   */
  completed_date: string | null;
  /**
   * 表示色
   */
  display_colorcode: string;
  /**
   * イベントの説明
   */
  event_description: string;
  /**
   * 申送事項
   */
  message: string;
  /**
   * 画像ファイルのURL
   */
  image_urls: Array<string>;
  /**
   * 未読フラグ
   */
  unread?: boolean;
  /**
   * 遅延作業として完了することの可否
   */
  readonly past_completion_available: boolean;
};

export class MaintEvent {
  id: number;
  name: string;
  set: string;
  subject1: string;
  subject2: string;
  subject3: string;
  scheduledDate: Dayjs;
  completedDate: Dayjs | null;
  displayColorcode: string;
  description: string;
  message: string;
  imageUrls: Array<string>;
  unread: boolean;
  readonly pastCompletionAvailable: boolean;

  constructor(maintEventData: MaintEventData) {
    this.id = maintEventData.maint_event_id;
    this.name = maintEventData.event_name;
    this.set = maintEventData.set;
    this.subject1 = maintEventData.subject1;
    this.subject2 = maintEventData.subject2;
    this.subject3 = maintEventData.subject3;
    this.scheduledDate = dayjs(maintEventData.scheduled_date);
    this.completedDate =
      maintEventData.completed_date != null
        ? dayjs(maintEventData.completed_date)
        : null;
    this.displayColorcode = maintEventData.display_colorcode;
    this.description = maintEventData.event_description;
    this.message = maintEventData.message;
    this.imageUrls = maintEventData.image_urls;
    this.unread = maintEventData.unread ?? false;
    this.pastCompletionAvailable =
      maintEventData.past_completion_available ?? false;
  }

  /**
   * ダミーデータを生成する。コンポーネントに対して暫定的に適当なMaintEventデータを渡したいときに使用する。
   * @returns ダミーデータ
   */
  static dummy(): MaintEvent {
    return new MaintEvent({
      maint_event_id: -1,
      event_name: "",
      set: "",
      subject1: "",
      subject2: "",
      subject3: "",
      scheduled_date: "20200101",
      completed_date: null,
      display_colorcode: "#000000",
      event_description: "",
      message: "",
      image_urls: [],
      past_completion_available: false,
    });
  }

  public setScheduledDate(scheduledDate: Dayjs) {
    this.scheduledDate = scheduledDate;
  }

  public isCompleted(): boolean {
    return this.completedDate !== null;
  }

  /**
   * 遅延ステータスの判定
   * 計画日が基準日よりも日付単位で前であれば、遅延ステータスとして判定する。
   * @param baseDate 基準日。指定しなければ、実行されたときの日付が使用される
   * @returns 遅延であればtrue
   */
  public isLate(baseDate: Dayjs = dayjs()): boolean {
    return this.scheduledDate.isBefore(baseDate, "day");
  }

  public status(baseDate: Dayjs = dayjs()): "waiting" | "late" | "completed" {
    if (this.isCompleted()) {
      return "completed";
    }
    if (this.isLate(baseDate)) {
      return "late";
    }
    return "waiting";
  }

  public statusColor(): string {
    const colors = {
      completed: "rgba(33, 33, 33, 0.06)",
      late: "#FEF3F2",
      waiting: "",
    };
    return colors[this.status()];
  }
}
