<template>
  <v-container>
    <alert-snack-bar
      v-model="state.popSnackBar"
      :type="state.snackBarType"
      :title="state.snackBarTitle"
      :message="state.snackBarMessage"
    />
    <v-dialog v-model="state.manualLinkModal.isShow" width="80%">
      <v-card>
        <v-card-item class="text-h6 py-3">
          URLリンクを追加する
          <template #append>
            <v-btn
              icon
              size="x-small"
              variant="text"
              @click="state.manualLinkModal.isShow = false"
            >
              <v-icon size="x-large">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-card-item>
        <v-divider />
        <v-card-text class="pa-6">
          <v-form
            ref="refFormManualLinkModal"
            v-model="state.manualLinkModal.formState"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="state.manualLinkModal.title"
                  color="primary"
                  variant="outlined"
                  density="compact"
                  label="表示テキスト"
                  placeholder="表示されるテキストを入力"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="state.manualLinkModal.url"
                  color="primary"
                  variant="outlined"
                  density="compact"
                  label="URL"
                  placeholder="https://"
                  :rules="letters1"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer />
          <v-btn
            variant="outlined"
            @click="state.manualLinkModal.isShow = false"
            >キャンセル</v-btn
          >
          <v-btn
            variant="flat"
            :color="state.manualLinkModal.formState ? 'primary' : undefined"
            :disabled="!state.manualLinkModal.formState"
            @click.stop="addManualLink"
            >リンクを追加</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col xl="12" cols="12" class="d-inline-flex">
        <h3 class="my-3">整備項目追加</h3>
        <v-spacer />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet color="white" elevation="1" class="pa-2">
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col xl="12" cols="12">
                <v-text-field
                  v-model="state.maintItem.maint_item_name"
                  color="primary"
                  density="compact"
                  persistent-counter
                  :rules="rule22"
                  counter="22"
                  label="整備項目名"
                  :loading="state.loading"
                  variant="outlined"
                  class="input-field-22c"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-inline-flex justify-start">
                <v-combobox
                  v-model="state.maintItem.set"
                  color="primary"
                  density="compact"
                  :items="comboBoxItems.setItems"
                  persistent-counter
                  :rules="rule3"
                  counter="3"
                  label="セット"
                  :loading="state.loading || state.comboboxLoading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-combobox>
                <v-combobox
                  v-model="state.maintItem.subject1"
                  color="primary"
                  density="compact"
                  :items="comboBoxItems.subject1Items"
                  persistent-counter
                  :rules="rule3"
                  counter="3"
                  label="対象1"
                  :loading="state.loading || state.comboboxLoading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-combobox>
                <v-combobox
                  v-model="state.maintItem.subject2"
                  color="primary"
                  density="compact"
                  :items="comboBoxItems.subject2Items"
                  persistent-counter
                  :rules="rule3"
                  counter="3"
                  label="対象2"
                  :loading="state.loading || state.comboboxLoading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-combobox>
                <v-combobox
                  v-model="state.maintItem.subject3"
                  color="primary"
                  density="compact"
                  :items="comboBoxItems.subject3Items"
                  persistent-counter
                  :rules="rule3"
                  counter="3"
                  label="対象3"
                  :loading="state.loading || state.comboboxLoading"
                  variant="outlined"
                  class="input-field-3c"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2"></v-divider>
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">整備計画日</h4>
                <v-menu
                  v-model="state.datepicker.isShow"
                  :close-on-content-click="false"
                  :offset="40"
                  location="bottom"
                  min-width="auto"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      v-bind="props"
                      v-model="state.datepicker.displayDate"
                      class="datepicker-textfield input-shift"
                      :loading="state.loading"
                      append-inner-icon="mdi-calendar"
                      color="primary"
                      density="compact"
                      readonly
                      variant="outlined"
                      :rules="ruleSchedule"
                    />
                  </template>
                  <v-locale-provider locale="ja">
                    <v-date-picker
                      v-model="state.datepicker.inputDate"
                      color="primary"
                      header=""
                      :title="
                        $dayjs(state.datepicker.inputDate).format('YYYY年')
                      "
                      class="elevation-1"
                      @update:model-value="state.datepicker.isShow = false"
                    />
                  </v-locale-provider>
                </v-menu>
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2"></v-divider>
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">作業周期</h4>
                <v-text-field
                  v-model="state.maintItem.days_of_maint_interval"
                  color="primary"
                  density="compact"
                  :rules="ruleInputMaintInterval"
                  type="text"
                  maxlength="4"
                  hint="1~9999の整数のみ可能です"
                  label="日数"
                  variant="outlined"
                  class="input-field-3c"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2" />
            <v-row>
              <v-col>
                <h4 class="mb-5">整備部品</h4>
                <h5>
                  MHIから購入する部品 (最大10個)<v-tooltip location="bottom">
                    <template #activator="{ props }">
                      <v-icon v-bind="props" class="ml-1 mb-1" size="small"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <span
                      >品目番号がe-Partsの品目番号と一致する場合、閲覧時に自動的にリンクとして表示され、<br />e-Partsの品目詳細ページにアクセスできるようになります。</span
                    >
                  </v-tooltip>
                </h5>
              </v-col>
            </v-row>
            <v-row
              v-for="(hinmoku, index) in state.maintItem.hinmokus_mhi"
              :key="`hinmoku-mhi-${index}`"
              class="mb-n6"
            >
              <v-col cols="3">
                <v-text-field
                  v-model="hinmoku.hinmoku_no"
                  color="primary"
                  label="品目番号"
                  variant="outlined"
                  density="compact"
                  hint="半角英数字、半角記号、半角カナのみ入力可能です"
                  persistent-counter
                  type="text"
                  counter="15"
                  maxlength="15"
                  :rules="ruleHinmokuNo"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="hinmoku.name"
                  color="primary"
                  label="名称など"
                  variant="outlined"
                  density="compact"
                  persistent-counter
                  type="text"
                  counter="100"
                  :rules="ruleHinmokuName"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="hinmoku.quantity"
                  color="primary"
                  label="個数"
                  variant="outlined"
                  density="compact"
                  hint="0~999の整数のみ入力可能です"
                  type="text"
                  maxlength="3"
                  :rules="ruleHinmokuQuantity"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
              <v-btn
                class="mt-4"
                icon
                size="x-small"
                variant="tonal"
                @click="deleteHinmoku(index)"
              >
                <v-icon> mdi-minus </v-icon>
              </v-btn>
            </v-row>
            <v-row class="mt-3 mb-2">
              <v-col>
                <v-btn
                  variant="outlined"
                  :disabled="state.maintItem.hinmokus_mhi.length >= 10"
                  @click="addInputHinmokuFrom()"
                >
                  <v-icon class="my-2" alt="search icon" size="large"
                    >mdi-plus</v-icon
                  >
                  整備部品を追加</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5>その他部品 (MHI以外・最大10個)</h5>
              </v-col>
            </v-row>
            <v-row
              v-for="(hinmoku, index) in state.maintItem.hinmokus_others"
              :key="`hinmoku-others-${index}`"
              class="mb-n6"
            >
              <v-col cols="3">
                <v-text-field
                  v-model="hinmoku.hinmoku_no"
                  label="品目番号"
                  color="primary"
                  density="compact"
                  persistent-counter
                  type="text"
                  counter="50"
                  maxlength="50"
                  :rules="ruleHinmokuOtherNo"
                  :loading="state.loading"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="hinmoku.name"
                  label="名称など"
                  color="primary"
                  density="compact"
                  persistent-counter
                  type="text"
                  counter="100"
                  :rules="ruleHinmokuName"
                  :loading="state.loading"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="hinmoku.quantity"
                  color="primary"
                  density="compact"
                  label="個数"
                  hint="0~999の整数のみ入力可能です"
                  type="text"
                  maxlength="3"
                  :rules="ruleHinmokuQuantity"
                  :loading="state.loading"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-btn
                class="mt-4"
                icon
                size="x-small"
                variant="tonal"
                @click="deleteHinmokuOther(index)"
              >
                <v-icon> mdi-minus </v-icon>
              </v-btn>
            </v-row>
            <v-row class="mt-3 mb-2">
              <v-col>
                <v-btn
                  variant="outlined"
                  :disabled="state.maintItem.hinmokus_others.length >= 10"
                  @click="addInputHinmokuOtherFrom()"
                >
                  <v-icon class="my-2" alt="search icon" size="large"
                    >mdi-plus</v-icon
                  >
                  整備部品を追加</v-btn
                >
              </v-col>
            </v-row>
            <v-divider class="mb-7" />
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">整備作業の説明 (200文字まで)</h4>
                <v-textarea
                  v-model="state.maintItem.maint_item_description"
                  color="primary"
                  label="テキストの入力"
                  counter="200"
                  persistent-counter
                  :rules="letters0_200"
                  :loading="state.loading"
                  variant="outlined"
                />
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2" />
            <v-row>
              <v-col>
                <h4 class="mb-5">整備詳細画像 (最大3つ)</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(image, index) in state.maintItem.images"
                :key="index"
              >
                <img
                  v-if="image.url != null"
                  :src="image.url"
                  :alt="image.name"
                  style="max-width: 300px; border: solid 1px black"
                />
                <v-btn
                  class="mx-2"
                  icon
                  size="x-small"
                  variant="tonal"
                  @click="deleteImage(image)"
                >
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="img_area"
                  block
                  height="100px"
                  variant="outlined"
                  :disabled="state.maintItem.images.length >= 3"
                  @click="openInputImage"
                >
                  <v-icon class="my-2" alt="search icon" size="x-large">
                    mdi-plus
                  </v-icon>
                </v-btn>
                <p>
                  <input
                    id="img"
                    ref="inputImage"
                    type="file"
                    accept="image/jpeg, image/png"
                    style="display: none"
                    multiple
                    @change="load_image"
                  />
                </p>
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-4"></v-divider>
            <v-row>
              <v-col>
                <h4 class="mb-5">整備詳細マニュアル添付</h4>
                <h5 class="mb-5">PDFファイル (最大3つ)</h5>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(manualFile, index) in state.maintItem.manual_files"
                :key="index"
                cols="12"
                class="mt-n4"
              >
                <v-icon size="small"> mdi-paperclip </v-icon>
                <a class="upload-file-name text-primary">
                  {{ manualFile.name }}
                </a>
                <v-btn
                  class="mx-2 delete-button"
                  icon
                  size="x-small"
                  variant="tonal"
                  @click="deleteManualFile(manualFile)"
                >
                  <v-icon size="x-small"> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="img_area"
                  block
                  height="100px"
                  variant="outlined"
                  :disabled="state.maintItem.manual_files.length >= 3"
                  @click="openInputManualFile"
                >
                  <v-icon class="my-2" alt="search icon" size="x-large">
                    mdi-plus
                  </v-icon>
                </v-btn>
                <p>
                  <input
                    id="a"
                    ref="inputManualFile"
                    type="file"
                    accept="application/pdf"
                    style="display: none"
                    multiple
                    @change="load_manual_file"
                  />
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5 class="mb-5">リンク (最大3つ)</h5>
              </v-col>
            </v-row>
            <v-row
              v-for="(link, index) in state.maintItem.manual_links"
              :key="`${index}-${link.url}`"
            >
              <v-col class="mt-n4">
                <a
                  :href="link.url"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-primary"
                  >{{ link.title }}</a
                >
                <v-btn
                  class="mx-2 delete-button"
                  icon
                  size="x-small"
                  variant="tonal"
                  @click="deleteManualLink(index)"
                >
                  <v-icon size="x-small">mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  variant="outlined"
                  :disabled="isDisableToAddManualLinks"
                  @click.stop="openManualLinkModal"
                >
                  <v-icon class="my-2" alt="search icon" size="large"
                    >mdi-plus</v-icon
                  >
                  URLリンクを追加</v-btn
                >
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-4"></v-divider>
            <h4 class="mb-5">工数</h4>
            <v-row>
              <v-col cols="12" class="d-inline-flex justify-start">
                <v-text-field
                  v-model="state.maintItem.man_in_man_hours"
                  color="primary"
                  density="compact"
                  :rules="ruleMan"
                  label="人数（人）"
                  hint="空欄もしくは1~999の整数のみ入力可能です"
                  type="text"
                  :loading="state.loading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-text-field>
                <v-text-field
                  v-model="state.maintItem.hour_in_man_hours"
                  color="primary"
                  density="compact"
                  :rules="ruleHour"
                  label="時間（h）"
                  hint="空欄もしくは0より大きく999未満の数値(小数は小数点以下2桁まで)のみ入力可能です"
                  type="text"
                  :loading="state.loading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="12" cols="12" class="d-flex justify-end">
                <v-btn
                  variant="outlined"
                  class="mr-4 cancel-btn"
                  :to="createRedirectLocation()"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  variant="flat"
                  color="primary"
                  class="mr-4"
                  :loading="state.registering"
                  @click="registerMaintItem"
                >
                  登録
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { reactive, ref, watch, computed } from "vue";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import { connectToApi } from "@/helpers/connectToApi";
import AlertSnackBar from "@/components/AlertSnackBar.vue";
import { get_base64_data_url } from "@/helpers/get_base64_data_url_from_file";
import { ulid } from "@/helpers/ulid";
import {
  maxImageSize,
  maxFileSize,
  acceptImageMimeTypes,
  acceptFileMimeTypes,
  ManualLink,
  Image,
  ManualFile,
  Part,
} from "@/models/MaintItem";
import { useAuthoritiesStore } from "@/stores/authorities";
import { requestGetMaintenanceClassification } from "@/helpers/api/getMaintenanceClassification";

type MaintItem = {
  maint_item_id: number;
  maint_task_id: number;
  plant_id: number;
  item_no: number;
  order: number;
  maint_item_name: string;
  set: string | undefined;
  subject1: string | undefined;
  subject2: string | undefined;
  subject3: string | undefined;
  days_of_maint_interval: number | null;
  scheduled_date: string;
  maint_item_description: string;
  man_in_man_hours: number | null;
  hour_in_man_hours: number | null;
  has_today_completed_task: boolean;
  manual_links: ManualLink[];
  images: Image[];
  manual_files: ManualFile[];
  hinmokus_mhi: Part[];
  hinmokus_others: Part[];
};
type StateDatePicker = {
  isShow: boolean;
  inputDate: Date | undefined;
  displayDate: string;
};
type StateComboBoxItems = {
  setItems: string[];
  subject1Items: string[];
  subject2Items: string[];
  subject3Items: string[];
};

/**
 * マニュアルリンク追加用モーダルで使う値
 */
type ManualLinkModal = {
  isShow: boolean;
  formState: boolean;
  title: string;
  url: string;
};

type State = {
  maintItem: MaintItem;
  popSnackBar: boolean;
  snackBarType: string;
  snackBarTitle: string;
  snackBarMessage: string;
  loading: boolean;
  registering: boolean;
  deleting: boolean;
  comboboxLoading: boolean;
  datepicker: StateDatePicker;
  manualLinkModal: ManualLinkModal;
};

type ApiResponse = {
  maint_item: MaintItem;
};

type ImageUploadApiResponse = {
  image_id: string;
  extension: string;
  url: string;
};

type FileUploadApiResponse = {
  file_id: string;
  extension: string;
  url: string;
};

type LocationData = {
  path: string;
  query: { [key: string]: string };
};

const storeAuthorities = useAuthoritiesStore();
const router = useRouter();
const refFormManualLinkModal = ref<HTMLFormElement>();

const form = ref(null);
const valid = ref(false);
const rule22 = [
  (v: string | null) =>
    (v != null && v.trim().length <= 22 && v.trim().length >= 1) ||
    "1文字以上22文字以下で入力して下さい",
];

const rule3 = [
  (v: string | null) =>
    v == null || v.trim().length <= 3 || "3文字以下で入力して下さい",
];
const ruleInputMaintInterval = [
  (v: string | null) =>
    (Number.isInteger(Number(v)) && Number(v) > 0 && Number(v) < 10000) ||
    "1~9999の整数を入力して下さい",
];
const checkScheduledDay = (v: string) => {
  if (v) {
    return dayjs(v).isAfter(dayjs().subtract(1, "d").endOf("day"));
  } else return false;
};
const ruleSchedule = [
  (v: string) => checkScheduledDay(v) || "今日以降の日付を入力して下さい",
];
const letters0_200 = [
  (v: string | null) =>
    (v != null && v.trim().length <= 200) || "200文字以下で入力して下さい",
];
const letters1 = [
  (v: string | null) =>
    (v != null && v.trim().length > 0) || "1文字以上で入力してください",
];
const ruleMan = [
  (v: string | null) =>
    v == null ||
    v == "" ||
    (Number.isInteger(Number(v)) && Number(v) > 0 && Number(v) < 1000) ||
    "1~999の整数を入力して下さい",
];
const ruleHour = [
  (v: string | null) => {
    if (v == null || v === "") return true;
    const num = parseFloat(v);
    if (isNaN(num))
      return "0より大きく999未満の数値を入力してください、小数の場合は小数点以下は2桁まで有効です";
    if (num <= 0 || num >= 999)
      return "0より大きく999未満の数値を入力してください、小数の場合は小数点以下は2桁まで有効です";
    if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(v))
      return "0より大きく999未満の数値を入力してください、小数の場合は小数点以下は2桁まで有効です";
    return true;
  },
];
const ruleHinmokuNo = [
  (v: string) =>
    /^[!-~｡-ﾟ¥]+$/.test(v) ||
    "半角英数字、半角記号、半角カナで入力してください",
  (v: string) => !!v.trim() || "品目番号を入力してください",
];
const ruleHinmokuOtherNo = [
  (v: string) => !!v.trim() || "品目番号をしてください",
];
const ruleHinmokuName = [
  (v: string | null) =>
    (v != null && v.trim().length <= 100) || "100文字以下で入力して下さい",
];
const ruleHinmokuQuantity = [
  (v: string | null) =>
    (Number.isInteger(Number(v)) && Number(v) >= 0 && Number(v) < 1000) ||
    "0~999の整数を入力して下さい",
];

const state = reactive<State>({
  maintItem: {
    maint_item_id: -1,
    maint_task_id: -1,
    plant_id: -1,
    item_no: -1,
    order: -1,
    maint_item_name: "",
    set: undefined,
    subject1: undefined,
    subject2: undefined,
    subject3: undefined,
    days_of_maint_interval: null,
    scheduled_date: "",
    maint_item_description: "説明： \n工具： \n資材： ",
    man_in_man_hours: null,
    hour_in_man_hours: null,
    has_today_completed_task: false,
    manual_links: [],
    images: [],
    manual_files: [],
    hinmokus_mhi: [],
    hinmokus_others: [],
  },
  popSnackBar: false,
  snackBarType: "error",
  snackBarTitle: "データの更新に失敗しました。",
  snackBarMessage: "データの更新に関する不具合が発生しています。",
  loading: false,
  registering: false,
  deleting: false,
  comboboxLoading: false,
  datepicker: {
    isShow: false,
    inputDate: undefined,
    displayDate: "",
  },
  manualLinkModal: {
    isShow: false,
    formState: false,
    title: "",
    url: "",
  },
});

const inputImage = ref();

const inputManualFile = ref();

// 画像ファイル選択画面を開く
const openInputImage = () => {
  inputImage.value.click();
};

// マニュアルファイル（PDF）選択画面を開く
const openInputManualFile = () => {
  inputManualFile.value.click();
};

// プレビュー表示用＋アップロード用に画像ファイルを格納する
const load_image = async (event: Event) => {
  const selectedFile = event.target;
  if (!(selectedFile instanceof HTMLInputElement)) return;
  if (selectedFile.files == null || selectedFile.files.length == 0) {
    state.maintItem.images = [];
    return;
  }

  const sum = selectedFile.files.length + state.maintItem.images.length;
  const limit = 3;
  if (sum <= limit) {
    const uploadImages: { [key: string]: File } = {};
    for (const file of selectedFile.files) {
      if (!acceptImageMimeTypes.includes(file.type.toLowerCase())) {
        state.snackBarTitle = "JPEG画像およびPNG画像以外は登録できません";
        state.snackBarMessage = `"${file.name}": ${file.type}`;
        state.snackBarType = "error";
        state.popSnackBar = true;
        continue;
      }
      if (file.size > maxImageSize) {
        state.snackBarTitle = "4MBより大きい画像は登録できません";
        state.snackBarMessage = `"${file.name}"は4MBより大きいです。 (${(
          file.size /
          1024 /
          1024
        ).toFixed(1)}MB)`;
        state.snackBarType = "error";
        state.popSnackBar = true;
        continue;
      }

      // ULIDを使用してIDを作成
      const id = ulid();

      // プレビューを早く表示するため、画像アップロードAPI実行前にbase64Urlをstateに格納
      const base64Url = await get_base64_data_url(file);
      state.maintItem.images.push({
        image_id: id,
        extension: file.type,
        name: file.name,
        url: base64Url,
      });

      // 今回アップロードする画像を格納
      uploadImages[id] = file;
    }

    // 画像アップロードAPIを実行
    for (const [id, file] of Object.entries(uploadImages)) {
      try {
        const res = await postImage(file, id);

        // APIでアップロード完了後、レスポンス結果を使ってstateを書き換え
        const index = state.maintItem.images.findIndex(
          (i) => i.image_id === id,
        );
        state.maintItem.images[index] = {
          image_id: res.image_id,
          extension: res.extension,
          name: file.name,
          url: res.url,
        };
      } catch (error) {
        // アップロードに失敗したらstateから画像を削除
        state.maintItem.images = state.maintItem.images.filter(
          (i) => i.image_id !== id,
        );

        state.snackBarTitle =
          "画像の登録時に不具合が発生しました。再度登録してください。";
        state.snackBarMessage = `"${file.name}": ${file.type}`;
        state.snackBarType = "error";
        state.popSnackBar = true;
      }
    }
  } else {
    state.snackBarTitle = "添付画像数超過";
    state.snackBarMessage = `添付可能な画像数は${limit}枚までです。`;
    state.snackBarType = "error";
    state.popSnackBar = true;
  }
  selectedFile.value = "";
};

// 画像データをS3へアップロード
const postImage = async (
  file: File,
  id: string,
): Promise<ImageUploadApiResponse> => {
  const plant_id = storeAuthorities.selectedAuthority.plantId;

  const response = await connectToApi<ImageUploadApiResponse>({
    method: "POST",
    url: "/api/uploadMaintItemImage",
    data: file,
    headers: {
      "Content-Type": file.type,
    },
    params: {
      plant_id: plant_id,
      image_id: id,
    },
  }).catch((e) => {
    throw e;
  });

  return response.data;
};

// 画像を削除する
const deleteImage = (image: Image): void => {
  state.maintItem.images = state.maintItem.images.filter(
    (i) => i.image_id !== image.image_id,
  );
};

// マニュアルファイル（PDF）
// ファイルのプレビュー表示用＋アップロード用にファイルを格納する
const load_manual_file = async (event: Event) => {
  const selectedFile = event.target;
  if (!(selectedFile instanceof HTMLInputElement)) return;
  if (selectedFile.files == null || selectedFile.files.length == 0) {
    state.maintItem.manual_files = [];
    return;
  }
  // 選択したファイルが4個以上 or ファイル選択後に添付ファイル数との合計が4個以上になる場合はエラーを表示
  const sum = selectedFile.files.length + state.maintItem.manual_files.length;
  const limit = 3;
  if (sum <= limit) {
    const uploadManualFiles: { [key: string]: File } = {};
    for (const file of selectedFile.files) {
      if (!acceptFileMimeTypes.includes(file.type.toLowerCase())) {
        state.snackBarTitle = "PDFファイル以外は登録できません";
        state.snackBarMessage = `"${file.name}": ${file.type}`;
        state.snackBarType = "error";
        state.popSnackBar = true;
        continue;
      }
      if (file.size > maxFileSize) {
        state.snackBarTitle = "4MBより大きいファイルは登録できません";
        state.snackBarMessage = `"${file.name}"は4MBより大きいです。 (${(
          file.size /
          1024 /
          1024
        ).toFixed(1)}MB)`;
        state.snackBarType = "error";
        state.popSnackBar = true;
        continue;
      }

      // ULIDを使用してIDを作成
      const id = ulid();

      // ファイルを早く表示するため、ファイルアップロードAPI実行前にbase64Urlをstateに格納
      const base64Url = await get_base64_data_url(file);
      state.maintItem.manual_files.push({
        file_id: id,
        extension: file.type,
        name: file.name,
        url: base64Url,
      });

      // 今回アップロードするファイルを格納
      uploadManualFiles[id] = file;
    }

    // ファイルアップロードAPIを実行
    for (const [id, file] of Object.entries(uploadManualFiles)) {
      try {
        const res = await postManualFile(file, id);

        // APIでアップロード完了後、レスポンス結果を使ってstateを書き換え
        const index = state.maintItem.manual_files.findIndex(
          (i) => i.file_id === id,
        );
        state.maintItem.manual_files[index] = {
          file_id: res.file_id,
          extension: res.extension,
          name: file.name,
          url: res.url,
        };
      } catch (error) {
        // アップロードに失敗したらstateからファイルを削除
        state.maintItem.manual_files = state.maintItem.manual_files.filter(
          (i) => i.file_id !== id,
        );

        state.snackBarTitle =
          "ファイルの登録時に不具合が発生しました。再度登録してください。";
        state.snackBarMessage = `"${file.name}": ${file.type}`;
        state.snackBarType = "error";
        state.popSnackBar = true;
      }
    }
  } else {
    state.snackBarTitle = "添付ファイル数超過";
    state.snackBarMessage = `添付可能なファイル数は${limit}個までです。`;
    state.snackBarType = "error";
    state.popSnackBar = true;
  }
  selectedFile.value = "";
};

// ファイルデータをS3へアップロード
const postManualFile = async (
  file: File,
  id: string,
): Promise<FileUploadApiResponse> => {
  const plant_id = storeAuthorities.selectedAuthority.plantId;

  const response = await connectToApi<FileUploadApiResponse>({
    method: "POST",
    url: "/api/uploadMaintItemFile",
    data: file,
    headers: {
      "Content-Type": file.type,
    },
    params: {
      plant_id: plant_id,
      file_id: id,
    },
  }).catch((e) => {
    throw e;
  });

  return response.data;
};

// ファイルを削除する
const deleteManualFile = (file: ManualFile): void => {
  state.maintItem.manual_files = state.maintItem.manual_files.filter(
    (f) => f.file_id !== file.file_id,
  );
};

/**
 * 整備項目作成後のリダイレクトに使用するLocationDataオブジェクトを作成する
 */
const createRedirectLocation = (): LocationData => {
  // RouteLocationRawではなくLocationDataとしたのは、queryを必須項目にしたかったため
  // エラー時などにqueryでリダイレクト先にAlertSnackBarの表示内容を渡すので
  const location = {
    path: "/operation-history",
    query: {},
  };

  return location;
};

/**
 * マニュアルリンク追加用モーダルを表示する
 */
const openManualLinkModal = () => {
  state.manualLinkModal.title = "";
  state.manualLinkModal.url = "";
  refFormManualLinkModal.value?.reset();
  state.manualLinkModal.isShow = true;
};

/**
 * マニュアルリンクを追加
 */
const addManualLink = () => {
  const title = state.manualLinkModal.title?.trim();
  const url = state.manualLinkModal.url.trim();
  const link: ManualLink = {
    title: title || url,
    url: url,
  };
  state.maintItem.manual_links.push(link);
  state.manualLinkModal.isShow = false;
};

/**
 * 指定したindexのマニュアルリンクを消す
 */
const deleteManualLink = (index: number) => {
  state.maintItem.manual_links.splice(index, 1);
};

/**
 * マニュアルリンクの追加が可能かどうか判定する
 */
const isDisableToAddManualLinks = computed<boolean>(() => {
  return state.maintItem.manual_links.length >= 3;
});

/**
 * 整備部品（MHI）の入力フォームを追加する
 */
const addInputHinmokuFrom = () => {
  state.maintItem.hinmokus_mhi.push({
    hinmoku_no: "",
    name: "",
    quantity: null,
  });
};

/**
 * 指定したindexの整備部品（MHI）を消す
 */
const deleteHinmoku = (index: number) => {
  state.maintItem.hinmokus_mhi.splice(index, 1);
};

/**
 * 整備部品（MHI以外）の入力フォームを追加する
 */
const addInputHinmokuOtherFrom = () => {
  state.maintItem.hinmokus_others.push({
    hinmoku_no: "",
    name: "",
    quantity: null,
  });
};

/**
 * 指定したindexの整備部品（MHI以外）を消す
 *
 */
const deleteHinmokuOther = (index: number) => {
  state.maintItem.hinmokus_others.splice(index, 1);
};

watch(
  () => state.datepicker.inputDate,
  () => {
    let result = "";
    if (state.datepicker.inputDate) {
      result = dayjs(state.datepicker.inputDate).format("YYYY/MM/DD");
      state.maintItem.scheduled_date = dayjs(state.datepicker.inputDate).format(
        "YYYYMMDD",
      );
    }
    state.datepicker.displayDate = result;
  },
);

// 添付ファイル数を監視し、4つ以上添付された場合はバーを表示
watch(
  () => state.maintItem.manual_files.length,
  () => {
    if (state.maintItem.manual_files.length > 3) {
      state.snackBarTitle = "添付ファイル数超過";
      state.snackBarMessage = "添付可能なファイル数は3つまでです。";
      state.snackBarType = "error";
      state.popSnackBar = true;
    }
  },
);

const registerMaintItem = () => {
  if (!valid.value) {
    if (form.value) {
      (form.value as HTMLFormElement & { validate: () => boolean }).validate();
      state.snackBarType = "warning";
      state.snackBarTitle = "登録できませんでした";
      state.snackBarMessage = "入力した値を確認して下さい";
      state.popSnackBar = true;
      return;
    }
  }
  state.registering = true;

  /**
   * 工数（人）が空欄の場合はnullに変換する
   * 入力がある場合は数値型に変換する
   */
  const convertManInput = (inputData: string | number | null) => {
    return Number(inputData) == 0 ? null : Number(inputData);
  };

  /**
   * 工数（時間）が空欄の場合はnullに変換する
   * 入力がある場合は文字列型に変換する
   */
  const convertHoursInput = (inputData: number | null) => {
    return Number(inputData) == 0 ? null : String(inputData);
  };

  /**
   * 整備部品の個数が空欄の場合は0に変換する
   * 入力がある場合は数値型に変換する
   */
  const convertHinmokusQuantityInput = (inputData: number | null) => {
    return Number(inputData) == 0 ? 0 : Number(inputData);
  };

  const callCreateMaintItem = (maintItem: MaintItem) =>
    connectToApi<ApiResponse>({
      method: "POST",
      url: "/api/createMaintItem",
      data: {
        plant_id: storeAuthorities.selectedAuthority.plantId,
        maint_item_name: maintItem.maint_item_name.trim(),
        set:
          maintItem.set == null || maintItem.set.trim() == ""
            ? "-"
            : maintItem.set.trim(),
        subject1:
          maintItem.subject1 == null || maintItem.subject1.trim() == ""
            ? "-"
            : maintItem.subject1.trim(),
        subject2:
          maintItem.subject2 == null || maintItem.subject2.trim() == ""
            ? "-"
            : maintItem.subject2.trim(),
        subject3:
          maintItem.subject3 == null || maintItem.subject3.trim() == ""
            ? "-"
            : maintItem.subject3.trim(),
        days_of_maint_interval: Number(maintItem.days_of_maint_interval),
        scheduled_date: maintItem.scheduled_date,
        maint_item_description: maintItem.maint_item_description,
        man_in_man_hours: convertManInput(maintItem.man_in_man_hours),
        hour_in_man_hours: convertHoursInput(maintItem.hour_in_man_hours),
        manual_links: maintItem.manual_links,
        images: maintItem.images.map((i) => {
          return {
            image_id: i.image_id,
            name: i.name,
            extension: i.extension,
          };
        }),
        manual_files: maintItem.manual_files.map((f) => {
          return {
            file_id: f.file_id,
            name: f.name,
            extension: f.extension,
          };
        }),
        hinmokus_mhi: maintItem.hinmokus_mhi.map((f) => {
          return {
            hinmoku_no: f.hinmoku_no,
            name: f.name,
            quantity: convertHinmokusQuantityInput(f.quantity),
          };
        }),
        hinmokus_others: maintItem.hinmokus_others.map((f) => {
          return {
            hinmoku_no: f.hinmoku_no,
            name: f.name,
            quantity: convertHinmokusQuantityInput(f.quantity),
          };
        }),
      },
    });

  callCreateMaintItem(state.maintItem)
    .then(() => {
      const location = createRedirectLocation();
      location.query.edit = "create";
      router.push(location);
      state.registering = false;
    })
    .catch((e) => {
      // エラーのときはチェックボックスを未完了状態に戻しておく
      state.snackBarType = "error";
      state.snackBarTitle = "データの更新に失敗しました。";
      state.snackBarMessage =
        e.response !== undefined
          ? e.response.data.message
          : "データの更新に関する不具合が発生しています。";
      state.popSnackBar = true;
    })
    .finally(() => {
      state.registering = false;
    });
};

// オートコンプリートのネタ用
const getMaintenanceClassification = async () => {
  state.comboboxLoading = true;
  try {
    const classification = await requestGetMaintenanceClassification(
      storeAuthorities.selectedAuthority.plantId,
    );
    comboBoxItems.setItems = classification.set;
    comboBoxItems.subject1Items = classification.subject1;
    comboBoxItems.subject2Items = classification.subject2;
    comboBoxItems.subject3Items = classification.subject3;
  } catch (e: any) {
    state.snackBarType = "error";
    state.snackBarTitle = "データの取得に失敗しました。";
    state.snackBarMessage =
      e.response !== undefined
        ? e.response.data.message
        : "データの取得に関する不具合が発生しています。";
    state.popSnackBar = true;
  } finally {
    state.comboboxLoading = false;
  }
};

const comboBoxItems = reactive<StateComboBoxItems>({
  setItems: [],
  subject1Items: [],
  subject2Items: [],
  subject3Items: [],
});

const init = async () => {
  if (!storeAuthorities.roleChecker.isUserAdmin) {
    // 管理者権限でない場合はTOP画面に戻る
    // 通常ありえないので遷移先でのスナックバーメッセージはなし
    router.push({
      path: "/",
    });
  }
  await getMaintenanceClassification();

  if (state.maintItem.scheduled_date) {
    state.datepicker.inputDate = dayjs(state.maintItem.scheduled_date).toDate();
    state.datepicker.displayDate = dayjs(state.maintItem.scheduled_date).format(
      "YYYY/MM/DD",
    );
  }
};

init();
</script>

<style scoped>
.input-field-22c {
  width: 400px;
}
.input-field-3c {
  max-width: 150px;
}
.datepicker-textfield {
  width: 180px;
}
.img_area {
  border: 1px dashed #a19a9a;
}
.delete-button {
  width: 20px;
  height: 20px;
}
.upload-file-name {
  cursor: default;
}
a.cancel-btn::before {
  opacity: 0;
}
.hinmoku-subtitle {
  font-weight: normal;
}
</style>
